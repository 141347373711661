.cm-line.error-highlight {
  background-color: rgba(255, 0, 0, 0.2); /* Light red background for error lines */
}

.error-tooltip {
  color: red;
  font-size: 12px;
  position: absolute;
  background: white;
  border: 1px solid red;
  border-radius: 4px;
  padding: 4px;
  z-index: 1000;
}

 .highlight-line {
    background-color: rgba(255, 0, 0, 0.2) !important; /* Light red background */
  }
